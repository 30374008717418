import React, { useState, useCallback, useEffect } from "react";
import { View, Text } from "react-native";
import { GiftedChat, Send } from "react-native-gifted-chat";
import { Configuration, OpenAIApi } from "openai";
import { v4 as uuidv4 } from "uuid";

const App = () => {
  const [messages, setMessages] = useState([]);

  const configuration = new Configuration({
    apiKey: "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",
  });

  const openai = new OpenAIApi(configuration);

  useEffect(() => {
    setMessages([
      {
        _id: 1,
        text: "Hello! Welcome to Diji GPT-4 Chatbot. Please ask me anything!",
        createdAt: new Date(),
        user: {
          _id: 2,
          name: "OpenAI",
          avatar: require("./assets/icon.png"),
        },
      },
    ]);
  }, []);

  const formatMessages = (messages) => {
    return messages.map((message) => {
      return {
        role: message.user._id === 1 ? "user" : "assistant",
        content: message.text,
      };
    });
  };

  const callOpenAI = async ({ messages }) => {
    const completion = await openai.createChatCompletion({
      model: "gpt-4",
      messages: messages,
    });
    console.log(completion.data.choices[0].message);
    setMessages((previousMessages) => {
      return GiftedChat.append(previousMessages, [
        {
          _id: uuidv4(),
          text: completion.data.choices[0].message.content,
          createdAt: new Date(),
          user: {
            _id: 2,
            name: "OpenAI",
            avatar: require("./assets/icon.png"),
          },
        },
      ]);
    });
  };

  const onSend = useCallback((messages = []) => {
    setMessages((previousMessages) => {
      console.log(GiftedChat.append(previousMessages, messages));
      console.log(
        callOpenAI({
          messages: formatMessages(
            GiftedChat.append(previousMessages, messages).reverse()
          ),
        })
      );
      return GiftedChat.append(previousMessages, messages);
    });
  }, []);

  return (
    <View
      style={{
        flex: 1,
        maxHeight: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          flex: 1,
          maxWidth: 768,
        }}
      >
        <GiftedChat
          renderSend={(props) => {
            return (
              <Send {...props}>
                <View style={{ padding: 8 }}>
                  <Text
                    style={{ fontSize: 18, color: "#009dff", marginBottom: 4 }}
                  >
                    Submit
                  </Text>
                </View>
              </Send>
            );
          }}
          messages={messages}
          onSend={(messages) => {
            onSend(messages);
          }}
          user={{
            _id: 1,
          }}
        />
      </View>
    </View>
  );
};

export default App;
